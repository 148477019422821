.navbar-brand {
    font-size: 2.65rem;
    font-weight: bold;
    color:#30a494 !important;
}

.nav-link:hover {
    text-decoration: underline;
}

.menu-active {
    font-size: large;
    font-weight: bolder;
    text-decoration: underline;
}

.projects-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#contact .form-group {
    margin-bottom: 2%;
}

#contact .form-control {
    margin-top: 1%;
}

#footer {
    position: fixed;
    bottom: 0;
    width: 100%;
}

#projects, #contact {
    min-height: 75vh;
}

#myInfo {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
}

#myInfo .img-fluid {
    width: 80%;
    max-height: 90%;
    margin: 2rem 0rem;
}

#myInfo .image {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@-webkit-keyframes up-down {
    0% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-10px);
    }
}

@keyframes up-down {
    0% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-10px);
    }
}